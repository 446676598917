import React from 'react';
import contentStyles from './content.module.css';

export const HTMLContent = ({ content, className }) => (
  <div
    className={`${className} ${contentStyles.content}`}
    dangerouslySetInnerHTML={{ __html: content }}
  />
);

const Content = ({ content, className }) => (
  <div className={`${className} ${contentStyles.content}`}>{content}</div>
);

export default Content;
